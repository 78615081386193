import { post } from "@/api/index";
import { HEADERS_DEVOPS, HEADERS_USER } from "../ServerMap";

// java埋点参数
export interface JavaEeventParams {
    // 事件Id
    eventId: number;
    // 用户ID
    userId: string;
    // 所在企业ID
    organizeId: string;
    // 来源页面（路径，上一个页面）
    prePage: string;
    // 当前页面（路径）
    page: string;

    // 具体事件参数
    ext: AnyObject;
}

// 上报错误日志
export const reportErrorLog = (data: AnyObject) => post("/api/devops/cloudShopAI-errorLog", { data: JSON.stringify(data) }, HEADERS_DEVOPS);

// 埋点接口
export const reportEvent = (params: JavaEeventParams) => post("/api/ai/event", params, { ...HEADERS_USER, customError: true });
