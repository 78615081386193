// 创作表单-生成分镜
export const CREATE_FORM_GENERATE_STORYBOARD: any = 247;

// 创作表单-生成分镜成功
export const CREATE_FORM_GENERATE_STORYBOARD_SUCCESS: any = 248;

// 创作表单-生成分镜失败
export const CREATE_FORM_GENERATE_STORYBOARD_FAIL: any = 249;

// 字幕编排-匹配画面
export const CREATE_FORM_MATCH_SCREEN: any = 250;

// 字幕编排-匹配画面成功
export const CREATE_FORM_MATCH_SCREEN_SUCCESS: any = 251;

// 字幕编排-匹配画面失败
export const CREATE_FORM_MATCH_SCREEN_FAIL: any = 252;

// 分镜编排-编辑视频-替换素材
export const CREATE_FORM_EDIT_VIDEO_REPLACE_MATERIAL: any = 253;

// 分镜编排-编辑视频-保存
export const CREATE_FORM_EDIT_VIDEO_SAVE: any = 254;

// 视频列表 - 下载视频
export const VIDEO_LIST_DOWNLOAD_VIDEO: any = 255;

// 视频列表 - 下载剪映工程
export const VIDEO_LIST_DOWNLOAD_PROJECT: any = 256;

// 视频列表 - 重新生成
export const VIDEO_LIST_REGENERATE: any = 257;

// 视频详情 - 下载视频
export const VIDEO_DETAIL_DOWNLOAD_VIDEO: any = 258;

// 视频详情 - 下载剪映工程
export const VIDEO_DETAIL_DOWNLOAD_PROJECT: any = 259;

// 请求错误
export const REQUEST_ERROR: any = 260;

// 故事板 - 替换备选素材
export const STORY_WALL_REPLACE_ALTERNATE_MATERIAL: any = 261;

// 故事板 - 修改字幕位置
export const STORY_WALL_CHANGE_SUBTITLE_POSITION: any = 262;

// 创作表单-生成视频
export const CREATE_FORM_GENERATE_VIDEO: any = 263;
