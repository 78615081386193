// 服务构造器
let headersConstruction = (serverName: string, timeout = 1000 * 30) => ({ "app-id": serverName, timeout });

export const HEADERS_WEB = headersConstruction("web_api_yk");

export const HEADERS_AUTH = headersConstruction("auth_ip_yk");

export const HEADERS_CRM_AI = headersConstruction("crm_ai_yk");

export const HEADERS_CRM_AI_LLM = headersConstruction("crm_ai_yk", 1000 * 60 * 10);

export const HEADERS_COMMON = headersConstruction("common_ip_yk");

export const HEADERS_DEVOPS = headersConstruction("devops_ip_yk");

export const HEADERS_USER = headersConstruction("user_bp_yk");
