import { reportEvent } from "@/api/event";
import * as types from "./eventTypes";
import { useUserStore } from "@/store/user";

// 转换模块类型成方法
type ModuleToFuns<T extends string, F = { (detail?: AnyObject): Promise<void> }> = {
    [key in T]: F;
};

// 上报埋点方法代理
export const log2java: ModuleToFuns<keyof typeof types> = new Proxy(
    { ...types },
    {
        get(target, property) {
            const eventId = Reflect.get(target, property);

            if (eventId === undefined) {
                return;
            }

            return async (detail: AnyObject = {}) => {
                const userStore = useUserStore();

                try {
                    const data = typeof detail === "function" ? await detail() : detail;

                    reportEvent({
                        // 事件Id
                        eventId,
                        // 用户ID
                        userId: userStore.id,
                        // 所在企业ID
                        organizeId: userStore.organizeId,
                        // 上一个页面
                        prePage: history.state?.back || "",
                        // 当前页面
                        page: history.state?.current || "",

                        // 具体事件参数
                        ext: data,
                    });
                } catch (error) {
                    console.error("log2java 事件上报失败：", error);
                }
            };
        },
    },
);
