import COS from "cos-js-sdk-v5";
import OSS from "ali-oss";

import { reportErrorLog } from "@/api/event";
import { getUploadToken, uploadFileForUrl } from "@/api/upload";
import { domain } from "@/hooks/useImages";
import { useAppStore } from "@/store/app";
import { useUserStore } from "@/store/user";
import { getFileMD5, guid } from "./fn";

interface UploadTempCredential {
    type: "cos" | "oss";
    bucket: string;
    region: string;
    tmpSecretId: string;
    tmpSecretKey: string;
    sessionToken: string;
    expired: number;
}

// 阿里云OSS预签名URL生成
const generateOSSPresignedUrl = (url: string, token: UploadTempCredential, newFileName?: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            const client = new OSS({
                region: token.region,
                bucket: token.bucket,
                accessKeyId: token.tmpSecretId,
                accessKeySecret: token.tmpSecretKey,
                stsToken: token.sessionToken,
            } as any);

            // 路径
            const path = url.replace(/^https?:\/\/[^/]+\//, "").replace(/\?.*$/, "");

            // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
            const fileName = path.substring(path.lastIndexOf("/") + 1);

            const signedUrl = client.signatureUrl(path, { response: { "content-disposition": `attachment; filename=${encodeURIComponent(newFileName || fileName)}` } });

            resolve(signedUrl);
        } catch (err) {
            console.error("生成OSS预签名URL失败:", err);
            reject(err);
        }
    });
};

// 腾讯云COS预签名URL生成（还没用到cos，所以方法还没有验证是否可用）
const generateCOSPresignedUrl = (url: string, token: UploadTempCredential, newFileName?: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const cos = new COS({
            getAuthorization: (_, callback) => {
                callback({
                    TmpSecretId: token.tmpSecretId,
                    TmpSecretKey: token.tmpSecretKey,
                    SecurityToken: token.sessionToken,
                    ExpiredTime: token.expired,
                    StartTime: Math.floor(Date.now() / 1000),
                });
            },
        });

        const fileName = url.substring(url.lastIndexOf("/") + 1);
        const key = url.replace(/^https?:\/\/[^/]+\//, "");

        cos.getObjectUrl(
            {
                Bucket: token.bucket,
                Region: token.region || "ap-shanghai",
                Key: key,
                Sign: true,
                Expires: 3600,
                Query: {
                    "response-content-disposition": `attachment;filename=${encodeURIComponent(newFileName || fileName)}`,
                },
            },
            (err, data) => {
                if (err) {
                    console.error("生成COS预签名URL失败:", err);
                    reject(err);
                    return;
                }
                resolve(data.Url);
            },
        );
    });
};

/**
 * 生成预签名下载URL
 */
export const generatePresignedUrl = async (url: string, newFileName?: string): Promise<string> => {
    // 获取上传凭证
    const token = await getUploadToken();

    // 根据类型选择不同的签名方法
    if (token.type === "oss") {
        return generateOSSPresignedUrl(url, token, newFileName);
    }

    return generateCOSPresignedUrl(url, token, newFileName);
};

class Upload {
    // 默认的模块名
    defaultModule = "default";

    // 匹配域名的正则
    DOMAIN_REG = /^((https|http):\/\/)*.+?(?=\/)/g;

    createUpload(fileType: string): Promise<FileList> {
        return new Promise((resolve, reject) => {
            // 创建input
            let input = document.createElement("input");
            // 设置为文件类型
            input.type = "file";
            // 限制上传类型
            input.accept = fileType;

            // 监听上传事件
            input.addEventListener("change", () => {
                // 回调传入文件
                resolve(input.files!);
                input.remove();
            });

            // 监听取消事件
            input.addEventListener("cancel", () => {
                input.remove();
                reject("取消上传");
            });

            // 调用上传
            input.click();
        });
    }

    // 上传到腾讯云
    uploadToCOS({ token, file, path, metaData, signal, onProgress, resolve, reject }: any) {
        const client = new COS({
            async getAuthorization(options, callback) {
                if (options) {
                    //
                }

                // 异步获取临时密钥
                callback({
                    TmpSecretId: token.tmpSecretId,
                    TmpSecretKey: token.tmpSecretKey,
                    SecurityToken: token.sessionToken,
                    ExpiredTime: token.expired,
                } as COS.GetAuthorizationCallbackParams);
            },
        });

        client.putObject(
            {
                Bucket: token.bucket,
                Region: "ap-shanghai",

                Key: path,

                StorageClass: "STANDARD",
                Body: file,

                "x-cos-meta-bid": metaData.bid,
                "x-cos-meta-name": metaData.originalFileName,
                "x-cos-meta-operator-id": metaData.operatorId,
                "x-cos-meta-module": metaData.module,
                "x-cos-meta-mid": metaData.mid,
                // 清除图片的旋转角度
                "x-cos-meta-orientation": 0,

                onProgress(progressData) {
                    onProgress(Math.floor(progressData.percent * 100));
                },
            } as COS.PutObjectParams,
            (err, data) => {
                if (err || data.statusCode !== 200) {
                    return reject(err || data);
                }

                resolve(data.Location.replace(this.DOMAIN_REG, domain.value));
            },
        );

        // 监听取消信号
        if (signal) {
            signal.addEventListener("abort", () => (client as any).cancel?.());
        }
    }

    // 上传到阿里云
    async uploadToOSS({ token, file, path, remotePath, metaData, signal, onProgress, resolve, reject }: any) {
        // 存起上传token
        const tokens: { clientTime: number; token: UploadTempCredential }[] = [{ clientTime: Date.now(), token }];

        // 创建 OSS 客户端
        let client = new OSS({
            // region填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
            region: token.region,
            // 填写Bucket名称。
            bucket: token.bucket,
            // 从STS服务获取的临时访问凭证。临时访问凭证包括临时访问密钥（AccessKeyId和AccessKeySecret）和安全令牌（SecurityToken）。
            accessKeyId: token.tmpSecretId,
            accessKeySecret: token.tmpSecretKey,
            stsToken: token.sessionToken,
            // 刷新STS token 的时间间隔
            refreshSTSTokenInterval: 1000 * 60,
            // 当STS信息过期时自动设置stsToken、accessKeyId、accessKeySecret的函数
            refreshSTSToken: async () => {
                let res = await this.getUploadToken(remotePath);

                // 添加新的token
                tokens.push({ clientTime: Date.now(), token: res });

                return {
                    accessKeyId: res.tmpSecretId,
                    accessKeySecret: res.tmpSecretKey,
                    stsToken: res.sessionToken,
                };
            },
            // 取消超时时间
            timeout: 0,
        });

        // 创建上传请求
        client
            .multipartUpload(path, file, {
                headers: {
                    // 指定该Object被下载时的名称。
                    "Content-Disposition": metaData.operatorId,
                },
                meta: {
                    bid: metaData.bid,
                    name: metaData.originalFileName,
                    "operator-id": metaData.operatorId,
                    module: metaData.module,
                    mid: metaData.mid,
                    // 清除图片的旋转角度
                    orientation: 0,
                } as any,
                // 设置并发上传的分片数量，默认值为5。
                parallel: 5,
                // 设置分片大小。默认值为1 MB，最小值为100 KB，最大值为5 GB。最后一个分片的大小允许小于100 KB。
                partSize: 1024 * 1024 * 2,
                // 分片上传进度、断点和返回值。
                progress: (p) => onProgress(Math.floor(p * 100)),
            })
            .then((res) => {
                resolve(`${domain.value}/${res.name}`);
            })
            .catch((error) => {
                reject(error);

                const { id, staffId, organizeId } = useUserStore();

                // 上报错误日志
                reportErrorLog({
                    // token历史
                    tokens,
                    // 错误
                    error,
                    // 用户id
                    userId: id,
                    // 员工id
                    staffId,
                    // 企业id
                    organizeId,
                    // 错误发生时间
                    errorClientTime: Date.now(),
                });
            });

        // 监听取消信号
        if (signal) {
            signal.addEventListener("abort", () => (client as any).cancel?.());
        }
    }

    async getUploadToken(remotePath: string) {
        const appStore = useAppStore();

        if (!domain.value) {
            await appStore.INIT_CONFIGER();
        }

        // 获取上传临时token
        let token = await getUploadToken(remotePath || appStore.brandId);

        return token;
    }

    // 上传文件
    uploadFile({ file, module = this.defaultModule, uuid = guid(), onProgress = (x: any) => x, signal, remotePath }: any): Promise<string> {
        return new Promise(async (resolve, reject) => {
            // 获取上传临时token
            let token = await this.getUploadToken(remotePath);

            const { brandId: beid } = useAppStore();
            const { id } = useUserStore();

            // 文件后缀
            let suffix = file.name.substring(file.name.lastIndexOf("."));

            let fileMD5 = await getFileMD5(file);

            // 如果在获取md5的过程中，用户取消了上传，则取消上传
            if (signal?.aborted) {
                // 取消上传
                return reject("取消上传");
            }

            // 完整的文件名
            let fileName = fileMD5 + suffix;

            // 存储的文件路径
            let path = remotePath ? `${remotePath}/${fileName}` : `${beid}/${module}/${fileName}`;

            // 原始文件名
            let originalFileName = encodeURIComponent(file.name);

            // 生成参数
            let params = {
                token,
                file,
                path,
                remotePath,
                metaData: {
                    bid: beid,
                    originalFileName,
                    operatorId: id,
                    module,
                    mid: uuid,
                },
                signal,
                onProgress,
                resolve,
                reject,
            };

            switch (token.type) {
                // 腾讯云
                case "cos":
                    return this.uploadToCOS(params);

                // 阿里云
                case "oss":
                    return this.uploadToOSS(params);

                default:
                    break;
            }
        });
    }

    /**
     * @Author: SAM
     * @Description: 上传图片
     * @Date: 2022-03-21 11:25:28
     * @param {string} module 业务模块名称
     * @param {string} fileType 限制上传类型
     * @param {function} onProgress 上传进度回调
     * @return {Promise<string>} 返回链接
     */
    image(module = this.defaultModule, { fileType = "image/*", uuid = guid(), onProgress = (x: any) => x } = {}): Promise<string> {
        return new Promise((resolve) => {
            this.createUpload(fileType).then((files) => resolve(this.uploadFile({ module, onProgress, uuid, file: files[0] })));
        });
    }

    /**
     * @Author: SAM
     * @Description: 上传视频
     * @Date: 2022-03-22 15:47:28
     * @param {string} module 业务模块名称
     * @param {string} fileType 限制上传类型
     * @param {function} onProgress 上传进度回调
     * @return {Promise<string>} 返回链接
     */
    video(module = this.defaultModule, { fileType = "video/*", uuid = guid(), onProgress = (x: any) => x } = {}): Promise<string> {
        return new Promise(async (resolve) => {
            this.createUpload(fileType).then((files) => resolve(this.uploadFile({ module, onProgress, uuid, file: files[0] })));
        });
    }

    /**
     * @Author: SAM
     * @Description: 上传音频
     * @Date: 2022-03-22 15:47:28
     * @param {string} module 业务模块名称
     * @param {string} fileType 限制上传类型
     * @param {function} onProgress 上传进度回调
     * @return {Promise<string>} 返回链接
     */
    audio(module = this.defaultModule, { fileType = "audio/*", uuid = guid(), onProgress = (x: any) => x } = {}): Promise<string> {
        return new Promise(async (resolve) => {
            this.createUpload(fileType).then((files) => resolve(this.uploadFile({ module, onProgress, uuid, file: files[0] })));
        });
    }

    /**
     * @Author: SAM
     * @Description: 上传文件
     * @Date: 2022-03-22 15:47:28
     * @param {string} module 业务模块名称
     * @param {string} fileType 限制上传类型
     * @param {function} onProgress 上传进度回调
     * @return {Promise<string>} 返回链接
     */
    file(module = this.defaultModule, { fileType = ".txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.zip,.rar", uuid = guid(), onProgress = (x: any) => x } = {}) {
        return new Promise(async (resolve) => {
            this.createUpload(fileType).then((files) => resolve(this.uploadFile({ module, onProgress, uuid, file: files[0] })));
        });
    }

    // 通过url上传
    url(url: string, module = this.defaultModule) {
        return uploadFileForUrl(url, module);
    }
}

export default new Upload();
