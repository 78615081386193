import { createApp } from "vue";
import { createPinia } from "pinia";

// pinia待久化
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import TDesign from "tdesign-vue-next";

import { loadExternalResources } from "@/utils/fn";

import router from "@/router";

// 引入组件库的少量全局样式变量
import "tdesign-vue-next/es/style/index.css";

// 全局样式
import "@/assets/styles/app.less";

// 路由守卫
import "@/router/router-guards";

import App from "./App.vue";

createApp(App).use(TDesign).use(router).use(createPinia().use(piniaPluginPersistedstate)).mount("#app");

// 引入 iconfont
loadExternalResources("//at.alicdn.com/t/c/font_4720980_g3wsmfktzk4.css");
