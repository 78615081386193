import { acceptHMRUpdate, defineStore } from "pinia";

import { useAppStore } from "@/store/app";
import { useLocalStorage } from "@/utils/local-storage";
import router, { loginRoutePath } from "@/router";
import { getUserInfo } from "@/api/user";

// 用户token
const userToken = useLocalStorage("token");
// 会员卡期限单位map
export const vipUnitMap = { 1: "月", 2: "季度", 3: "年" };

// 会员卡期限单位
export type VipUnit = keyof typeof vipUnitMap;

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            // 员工ID
            staffId: "",
            // 用户ID
            id: "",
            // 企业ID
            organizeId: "",
            // 用户昵称
            nickname: "",
            // 用户积分
            coin: 0,
            // 会员卡信息
            vipInfo: {
                // 会员卡类型ID
                productId: 0,
                // 会员卡名称
                name: "string",
                // 会员卡类型
                type: 0,
                // 赠送积分
                coin: 0,
                // 剩余天数，正数表示剩余天数，负数表示已过期天数
                balance: 0,
                // 特殊说明 例如：SVIP会员将在 2023-10-12 生效
                description: "string",
                // 会员卡期限单位
                vipPeriod: -1 as VipUnit,
            },
        };
    },
    getters: {
        // 用户token, 防止从外面修改
        token: () => userToken.value,
    },
    actions: {
        SET_TOKEN(token: string | null) {
            userToken.value = token;
        },
        // 退出登录
        LOGOUT() {
            // 退出登录
            this.SET_TOKEN(null);
            this.RESET_CURRENT_USER();
            router.replace(loginRoutePath);
        },
        // 清空用户信息
        RESET_CURRENT_USER() {
            this.$reset();
        },

        // 获取用户信息
        async GET_USER_INFO() {
            const appStore = useAppStore();

            let { bid, realName, id, staffId, coin, vipInfo, organizeId } = await getUserInfo();

            // 设置品牌id
            appStore.SET_BRAND_ID(bid);

            // 员工ID
            this.staffId = staffId;
            // 用户ID
            this.id = id;
            // 企业ID
            this.organizeId = organizeId;
            // 用户昵称
            this.nickname = realName;
            // 用户积分
            this.coin = coin;
            // 会员卡信息
            this.vipInfo = vipInfo;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
    // import.meta.hot.accept(() => location.reload());
}
