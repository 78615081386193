import { get, post } from "@/api/index";
import { HEADERS_COMMON, HEADERS_WEB } from "../ServerMap";
import { useAppStore } from "@/store/app";

export const getUploadToken = async (modelName: string | number = "unknow") => {
    let res = await get("/api/ai/common/upload/credential", { modelName }, { ...HEADERS_COMMON, bid: useAppStore().brandId, cacheDuration: 60 });

    // 如果时间超过1分钟，则强制重新获取
    if (Date.now() > (res.expired - 60) * 1000) {
        res = await get("/api/ai/common/upload/credential", { modelName }, { ...HEADERS_COMMON, bid: useAppStore().brandId, cacheDuration: 60, force: true });
    }

    return res;
};

// 代理下载
export const getDownloadFile = (url: string, newFileName = "") => get("/web/content/download/", { url, newFileName }, { ...HEADERS_WEB, customError: true, responseType: "blob" });

// 代理上传
export const uploadFileForUrl = (url: string, module: string) => post("/api/upload/url", { url, module }, { ...HEADERS_COMMON, timeout: 30000 });
